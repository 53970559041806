import { h, Component } from 'preact';
import {
  DEFAULT_ROTATION,
  DEFAULT_SCALE,
  ISgwtPdfReaderI18NLanguage, IPdfReaderProps, ISgwtPdfReaderState, ROTATION_FACTOR,
  SCALE_FACTOR
} from '../sgwt-pdf-reader.types';
import { FullScreenControl } from './FullScreenControl';
import NavigationControls from './NavigationControls';
import PdfCanvas from './PdfCanvas';
import { PrintControl } from './PrintControl';
import { RotationControls } from './RotationControls';
import { ZoomControls } from './ZoomControls';
import { IWidgetConfigurationContext } from '@sgwt-widget/core';
import { SGWT_PDF_READER_I18N } from '../sgwt-pdf-reader.i18n';
import { getInternationalizationLabels } from '../../common/sgwt-widgets-utils';
import { BUS_GLOBAL_LANGUAGE } from '../../common/bus-topics';
import { SubscriptionHandle } from '@sgwt-widget/core/lib/bus/Bus';

export default class SgwtPdfReader extends Component<IPdfReaderProps, ISgwtPdfReaderState> {
  private element: HTMLElement | undefined;
  private busSubscription?: SubscriptionHandle;
  public context!: IWidgetConfigurationContext;
  public state: ISgwtPdfReaderState = {
    language: 'en',
    pdfJsReady: false,
    scale: DEFAULT_SCALE,
    rotation: DEFAULT_ROTATION,
    currentPage: 0,
    pagesCount: 0,
    displayFullDocument: true,
    displayCommands: true,
    isFullScreen: false,
    printing: false
  };

  public componentWillMount() {
    this.checkPdfJsAvailability(1);

    // Receive language updates
    this.busSubscription = this.context.widgetConfiguration.bus
      .subscribe<string>(BUS_GLOBAL_LANGUAGE, language => {
        this.setState({
          language: language
        });
      });
  }

  public componentWillUnmount() {
    if (this.busSubscription) {
      this.context.widgetConfiguration.bus.unsubscribe(this.busSubscription);
    }
  }

  public componentWillUpdate(nextProps: IPdfReaderProps, nextState: ISgwtPdfReaderState) {
    if (this.state.currentPage !== nextState.currentPage) {
      this.loadPage(nextState.currentPage);
    }
  }

  rotateLeft() {
    this.setState({
      rotation: this.state.rotation - ROTATION_FACTOR
    });
  }
  rotateRight() {
    this.setState({
      rotation: this.state.rotation + ROTATION_FACTOR
    });
  }

  zoomIn() {
    this.setState({
      scale: this.state.scale + SCALE_FACTOR
    });
  }
  zoomOut() {
    this.setState({
      scale: Math.max(this.state.scale - SCALE_FACTOR, SCALE_FACTOR)
    });
  }
  zoomReset() {
    this.setState({
      scale: DEFAULT_SCALE
    });
  }

  private getPageToPrint(pageNumber: number, iframe: HTMLIFrameElement, scale: number) {
    this.state.pdfDocument.getPage(pageNumber)
      .then((page: any) => {
        const canvas: HTMLCanvasElement = iframe.contentWindow!.document.createElement('canvas');
        const viewport = page.getViewport(scale, 0);
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };
        iframe.contentWindow!.document.body.appendChild(canvas);
        const renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          if (pageNumber === this.state.pagesCount) {
            iframe.focus();
            iframe.contentWindow!.print();
            document.body.removeChild(iframe);
            this.setState({
              printing: false
            });
          } else {
            this.getPageToPrint(pageNumber + 1, iframe, scale);
          }
        });
      });
  }

  print() {
    this.setState({
      printing: true,
      isFullScreen: false // since the popup will remove the full screen mode
    });
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.contentWindow!.document.write('<body></body>');
    const scale = 297 / 210; // For A4 format
    this.getPageToPrint(1, iframe, scale);
  }

  fullScreen() {
    const element: any = this.element;
    if (element) {
      (element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen ||
        element.msRequestFullscreen).call(element);
      this.setState({
        isFullScreen: true
      });
    }
  }

  exitFullScreen() {
    const doc: any = document;
    (doc.exitFullscreen || doc.webkitExitFullscreen || doc.mozCancelFullScreen ||
      doc.msExitFullscreen).call(doc);
    this.setState({
      isFullScreen: false
    });
  }

  displayCommands() {
    // this.setState({
    //   displayCommands: true
    // });
  }

  hideCommands() {
    // this.setState({
    //   displayCommands: false
    // });
  }

  /**
   * Change the current page. The given page should be valid (positive number, lower than the pages count and different
   * from the current page).
   * @param {number} newPage the new page to display.
   */
  changePage(newPage: number) {
    if (newPage > 0 && newPage <= this.state.pagesCount && newPage !== this.state.currentPage) {
      this.setState({
        currentPage: newPage
      });
    }
  }
  firstPage() {
    this.changePage(1);
  }
  previousPage() {
    this.changePage(this.state.currentPage - 1);
  }
  nextPage() {
    this.changePage(this.state.currentPage + 1);
  }
  lastPage() {
    this.changePage(this.state.pagesCount);
  }

  checkPdfJsAvailability(retry: number) {
    const pdfJs = (window as any).PDFJS;
    if (pdfJs && pdfJs.getDocument) {
      this.setState({
        pdfJsReady: true
      });
      this.loadPdf();
    } else {
      // PDFJS is not loaded or not ready yet...
      if (retry < 10) {
        setTimeout(() => {
          this.checkPdfJsAvailability(retry + 1);
        }, 100 * retry);
      } else {
        this.context.widgetConfiguration.log('[ERROR] PDFJS library does not seem to be available!');
      }
    }
  }

  /**
   * pdfUrl can be a string or an object. Therefore, we try to parse the value, and if we fail, we consider as a string.
   * cf. https://github.com/mozilla/pdf.js/blob/master/src/display/api.js#L218
   */
  private getPDFUrl(value: string): string | object {
    try {
      // Try default JSON parsing.
      return JSON.parse(value);
    } catch (_) {
      try {
        // Now replace all `'` to `"` and then parse with `JSON.parse` function.
        return JSON.parse(value.replace(/'/g, '"'));
      } catch (_) {
        return value;
      }
    }
  }

  loadPdf() {
    const pdfJs = (window as any).PDFJS;
    if (pdfJs && this.props.pdfUrl) {
      const docUrl = this.getPDFUrl(this.props.pdfUrl);
      pdfJs.getDocument(docUrl)
        .then((pdf: any) => {
          this.setState({
            pdfDocument: pdf,
            pagesCount: pdf.numPages,
            currentPage: 1
          });
          this.loadPage(1);
          this.props.onPdfLoaded(pdf.numPages);
        }, (err: Error) => {
          this.context.widgetConfiguration.log('[ERROR] Could not load PDF', err);
        });
    } else if (this.props.pdfUrl) {
      this.context.widgetConfiguration.log('[ERROR] Could not load PDF since PDF.js is not available');
    }
  }

  loadPage(page: number) {
    const pdf = this.state.pdfDocument;
    if (pdf) {
      pdf.getPage(page)
        .then((page: any) => {
          this.setState({
            page: page
          });
          this.props.onPageLoaded(this.state.currentPage);
        }, (err: Error) => {
          this.context.widgetConfiguration.log(`[ERROR] Could not load page ${page} from PDF`);
        });
    }
  }

  onPageRendered() {
    this.props.onPageRendered(this.state.currentPage);
  }

  render(props: IPdfReaderProps) {
    if (!this.state.pdfJsReady) {
      return null;
    }
    const labels: ISgwtPdfReaderI18NLanguage =
      getInternationalizationLabels(SGWT_PDF_READER_I18N, props.additionalI18n, this.state.language);

    const pagination = props.hideCommands || props.hideNavigationCommands ? undefined :
      <NavigationControls
        labels={labels}
        currentPage={this.state.currentPage}
        pagesCount={this.state.pagesCount}
        onPageChanged={newPage => this.changePage(newPage)}
      />;

    const rotations = props.hideCommands || props.hideRotationCommands ? undefined :
      <RotationControls
        labels={labels}
        onRotateLeft={() => this.rotateLeft()}
        onRotateRight={() => this.rotateRight()}
      />;

    const zoom = props.hideCommands || props.hideZoomCommands ? undefined :
      <ZoomControls
        labels={labels}
        onZoomIn={() => this.zoomIn()}
        onZoomOut={() => this.zoomOut()}
        onZoomReset={() => this.zoomReset()}
      />;

    const print = props.hideCommands || props.hidePrintCommand ? undefined :
      <PrintControl
        labels={labels}
        printing={this.state.printing}
        onPrint={() => this.print()}
      />;

    const fullScreen = props.hideCommands || props.hideFullScreenCommand ? undefined :
      <FullScreenControl
        labels={labels}
        isFullScreen={this.state.isFullScreen}
        onFullScreen={() => this.fullScreen()}
        onExitFullScreen={() => this.exitFullScreen()}
      />;

    const commands = !props.hideCommands && this.state.displayCommands ?
      <div class="pdf-root-container mt-2"
           onMouseEnter={() => this.displayCommands()}
           onMouseLeave={() => this.hideCommands()}>
        {pagination}
        {rotations}
        {zoom}
        {print}
        {fullScreen}
      </div> : null;

    return (
      <div class={`sgwt-pdf-reader ${this.state.isFullScreen ? 'full-screen' : ''}`}
           ref={ (element: HTMLElement) => { this.element = element; } }>
        {commands}

        <div class="pdf-root-container mt-2">
          <div class="canvas-container">
            <PdfCanvas
              labels={labels}
              page={this.state.page}
              rotation={this.state.rotation}
              scale={this.state.scale}
              canvasWidth={this.props.canvasWidth}
              onPageRendered={() => this.onPageRendered()}
            />
          </div>
        </div>
      </div>
    );
  }
}
