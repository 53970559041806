/**
 * SkateJS provides several default types for the `props`, including the `object` type.
 * However, it only accepts valid JSON format, which means that on the HTML side, we need to do:
 *
 *  - escaping the `"` with `&quot;`: `<my-elt my-prop="{&quot;foo&quot;: &quot;bar&quot;, &quot;id&quot;: 42}"></my-elt>`
 *  - using `'` to wrap the value, which is not really correct: `<my-elt my-prop='{"foo": "bar", "id": 42}'></my-elt>`
 *
 *  This customized type accepts a third way to write it, by using `'` instead of `"` for JSON object, like in
 *  `<my-elt my-prop="{'foo': 'bar', 'id': 42}"></my-elt>`
 *
 * https://skatejs.gitbooks.io/skatejs/content/docs/api/prop.html
 * https://github.com/skatejs/skatejs/blob/master/src/api/prop.js (! currently in branch `5.x`)
 */
const attribute = Object.freeze({ source: true, attribute: true });
const createProp = (obj: any) => Object.freeze({ ...{ attribute }, ...obj });

const object = createProp({
  default: Object.freeze({}),

  serialize: JSON.stringify,

  deserialize: (value: string): object => {
    try {
      // Try default JSON parsing, working with the first two approaches.
      return JSON.parse(value);
    } catch (e) {
      try {
        // Now replace all `'` to `"` and then parse with `JSON.parse` function. Works for the 3rd approach.
        return JSON.parse(value.replace(/'/g, '"'));
      } catch (e2) {
        return {};
      }
    }
  }
});

export const sgwtSkateProps = {
  object: object
};
