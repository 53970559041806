exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sgwt-widgets-icon{line-height:1!important;display:inline-block;height:24px;width:24px}.sgwt-widgets-icon.icon-sm{height:18px;width:18px}.sgwt-widgets-icon.icon-lg{height:32px;width:32px}.sgwt-widgets-icon.icon-xl{height:48px;width:48px}.sgwt-account-center-panel,.sgwt-help-center-panel{width:384px}@media screen and (max-width:576px){.sgwt-account-center-panel,.sgwt-help-center-panel{width:100%}}@media screen and (min-width:768px) and (max-width:992px){.sgwt-account-center-panel,.sgwt-help-center-panel{width:397px}}@media screen and (min-width:1608px){.sgwt-account-center-panel,.sgwt-help-center-panel{width:474px}}@keyframes _3Z2bYKNRLIADeOVgU05xq0{0%{transform:rotate(0)}to{transform:rotate(359deg)}}.animation-spin{animation:spin 2s infinite linear}", ""]);

// exports
exports.locals = {
	"spin": "_3Z2bYKNRLIADeOVgU05xq0"
};