import { Component, h } from 'preact';
import { SvgIcon } from './SvgIcon';

interface IRatingSelectorProps {
  container: 'popover' | 'form';
  isValid: boolean;
  rating?: number;
  ratingSelected: (rating: number) => void;
  starSize: number;
}

interface IRatingSelectorState {
  ratingSelection: number;
}

export class RatingSelector extends Component<IRatingSelectorProps, IRatingSelectorState> {
  constructor(props: IRatingSelectorProps) {
    super(props);
    this.state = {
      ratingSelection: 0
    };
  }

  public render(props: IRatingSelectorProps, state: IRatingSelectorState) {
    const filled = state.ratingSelection === 0 ? (props.rating || 0) : state.ratingSelection;
    return (
      <div class={props.isValid ? '' : 'd-inline-block border-bottom border-danger'}>
        {
          [0, 0, 0, 0, 0].map((_e, index) => {
            const starRating = index + 1;
            const moveIn = () => this.setState({ ratingSelection: starRating });
            const moveOut = () => this.setState({ ratingSelection: 0 });
            const select = () => this.props.ratingSelected(starRating);
            return (
              <button
                class={`btn btn-link ${props.container === 'popover' ? 'text-black' : 'text-primary'}`}
                type="button"
                onMouseEnter={moveIn} onMouseLeave={moveOut} onClick={select}
              >
                <SvgIcon
                  type={starRating > filled ? 'star_outline' : 'star'}
                  class="icon"
                  width={props.starSize}
                  height={props.starSize}
                />
              </button>
            );
          })
        }
      </div>
    );
  }
}