import { h, Component } from 'preact';

export interface IBasicDropdownState {
  visible: boolean;
}
export interface IBasicDropdownProps {
  label: string;
  labelMobile?: string;
  class?: string;
  linkClasses?: string;
}

/**
 * This is a basic implementation of the dropdown Bootstrap component, which does not require any additional libraries
 * (react-bootstrap / reactstrap / jQuery / whatever).
 */
export default class BasicDropdown extends Component<IBasicDropdownProps, IBasicDropdownState> {
  handleOutsideClickFn: (event: Event) => void;
  element?: HTMLLIElement;
  public state: IBasicDropdownState = {
    visible: false
  };

  constructor(props: IBasicDropdownProps) {
    super(props);
    this.handleOutsideClickFn = this.handleOutsideClick.bind(this);
  }

  /**
   * Handle the click when the dropdown menu is visible. Check if the click is made outside the menu to close it.
   * @param {Event} event
   */
  private handleOutsideClick(event: Event) {
    if (this.element && !this.element.contains(event.target as Node)) {
      this.switchVisibility();
    }
  }

  private switchVisibility() {
    if (this.state.visible) {
      // Menu is visible, we remove the event listener on click / touchend.
      document.removeEventListener('click', this.handleOutsideClickFn, false);
      document.removeEventListener('touchend', this.handleOutsideClickFn, false);
    } else {
      // Menu is not visible, we add the event listener on click / touchend.
      document.addEventListener('click', this.handleOutsideClickFn, false);
      document.addEventListener('touchend', this.handleOutsideClickFn, false);
    }
    this.setState({
      visible: !this.state.visible
    });
  }

  render(props: IBasicDropdownProps) {
    return (
      <li class={`pointer ${props.class}`} ref={(elt: HTMLLIElement) => { this.element = elt; }}>
        <a onClick={() => { this.switchVisibility(); }}
          style="cursor: pointer"
          class={`dropdown-toggle ${props.linkClasses || ''}`} data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span class="d-none d-sm-inline-block">{props.label}</span>
          <span class="d-sm-none">{props.labelMobile || props.label}</span>
        </a>
        <div class={`dropdown-menu dropdown-menu-right ${this.state.visible && 'visible show'}`}
          aria-labelledby="dropdownMenuButton">
          {this.props.children}
        </div>
      </li>
    );
  }
}
