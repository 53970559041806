export interface ISgwtPdfReaderI18NLanguage {
  loading: string;
  first: string;
  previous: string;
  next: string;
  last: string;
  rotateLeft: string;
  rotateRight: string;
  zoomIn: string;
  zoomOut: string;
  zoomReset: string;
  print: string;
  fullScreen: string;
  exitFullScreen: string;
  changePage: string;
  [key: string]: string;
}

export interface ISgwtPdfReaderI18N {
  [key: string]: ISgwtPdfReaderI18NLanguage;
}

export interface ISgwtPdfReaderState {
  language?: string;
  currentPage: number;
  displayFullDocument: boolean;
  displayCommands: boolean;
  page?: any;
  pagesCount: number;
  pdfDocument?: any;
  pdfJsReady: boolean;
  rotation: number;
  scale: number;
  isFullScreen: boolean;
  printing: boolean;
}

export interface ISgwtPdfReaderProps {
  pdfUrl?: string;
  displayFullDocument?: boolean;
  canvasHeight?: number;
  canvasMaxHeight?: number;
  canvasWidth?: number;
  fixedCommands?: boolean;
  hideCommands?: boolean;
  hideNavigationCommands?: boolean;
  hideRotationCommands?: boolean;
  hideZoomCommands?: boolean;
  hidePrintCommand?: boolean;
  hideFullScreenCommand?: boolean;
  i18n?: ISgwtPdfReaderI18N;
  importPdfjsLib?: boolean;
}

export interface IPdfReaderProps {
  pdfUrl?: string;
  displayFullDocument?: boolean;
  canvasHeight?: number;
  canvasMaxHeight?: number;
  canvasWidth?: number;
  fixedCommands?: boolean;
  hideCommands?: boolean;
  hideNavigationCommands?: boolean;
  hideRotationCommands?: boolean;
  hideZoomCommands?: boolean;
  hidePrintCommand?: boolean;
  hideFullScreenCommand?: boolean;
  additionalI18n?: ISgwtPdfReaderI18N;
  importPdfjsLib?: boolean;
  onPdfLoaded(pagesCount: number): void;
  onPageLoaded(page: number): void;
  onPageRendered(page: number): void;
}

export interface IPdfCanvasProps {
  labels: ISgwtPdfReaderI18NLanguage;
  page?: any;
  canvasWidth?: number;
  canvasHeight?: number;
  canvasMaxHeight?: number;
  rotation: number;
  scale: number;
  onPageRendered(): void;
}

export const DEFAULT_SCALE = 1.0;
export const SCALE_FACTOR = .25;

export const DEFAULT_ROTATION = 0;
export const ROTATION_FACTOR = 90;
