import { h, Component } from 'preact';
import { DEFAULT_ROTATION, DEFAULT_SCALE, IPdfCanvasProps } from '../sgwt-pdf-reader.types';
import { SvgIcon } from '../../common/components';

export default class PdfCanvas extends Component<IPdfCanvasProps> {
  private canvas: HTMLCanvasElement | undefined;
  private rendering: boolean;

  constructor(props: IPdfCanvasProps) {
    super(props);
    this.rendering = false;
  }

  shouldComponentUpdate(nextProps: IPdfCanvasProps): boolean {
    return nextProps.page !== this.props.page ||
      nextProps.rotation !== this.props.rotation ||
      nextProps.scale !== this.props.scale;
  }

  componentDidUpdate() {
    this.renderPdf();
  }

  calculateWidth(viewport: any): number {
    if (this.props.canvasWidth) {
      return this.props.canvasWidth;
    }
    if (this.props.canvasHeight) {
      return (this.props.canvasHeight * viewport.width) / viewport.height;
    }
    return viewport.width;
  }

  renderPdf() {
    const {page} = this.props;
    const {canvas} = this;
    if (page && canvas && !this.rendering) {
      this.rendering = true;
      let viewport = page.getViewport(this.props.scale || DEFAULT_SCALE, this.props.rotation || DEFAULT_ROTATION);
      const canvasContext = canvas.getContext('2d');
      const width = this.calculateWidth(viewport);
      // const scale = width / viewport.width;
      const scale = this.props.scale;
      viewport = page.getViewport(scale, this.props.rotation || DEFAULT_ROTATION);
      const height = viewport.height;

      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      canvas.width = width;
      canvas.height = height;
      canvas.classList.add('border');

      const task = page.render({canvasContext, viewport});
      task.promise.then(() => {
        // TODO emit event
        this.rendering = false;
        this.props.onPageRendered();
      });
    }
  }

  render(props: IPdfCanvasProps) {
    const {page} = this.props;
    return page ? <canvas ref={ (c: HTMLCanvasElement) => { this.canvas = c; } }/> :
      <span class="loading-indicator">
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type="autorenew" class="animation-spin"/>
        </i>
        <span class="loading-indicator-message">{ props.labels.loading }</span>
      </span>;
  }
}
