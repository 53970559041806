import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
  WithGlobalCss,
} from '@sgwt-widget/core';
import { ComponentProps, props, emit } from 'skatejs';
import PdfReader from './components/PdfReader';
import { ISgwtPdfReaderProps } from './sgwt-pdf-reader.types';
import { sgwtSkateProps } from '../common/SkateJsCustomProp';
import { startWidgetMonitoring, SgwtWidgetName } from '../common/monitoring';
import * as css from './sgwt-pdf-reader.scss';

export class SgwtPdfReader extends SgWidget<ISgwtPdfReaderProps> {
  public static is = 'sgwt-pdf-reader';
  private reference?: any;

  public static props: ComponentProps<ISgwtPdfReaderProps> = {
    i18n: sgwtSkateProps.object,
    pdfUrl: props.string,
    displayFullDocument: props.boolean,
    canvasHeight: props.number,
    canvasMaxHeight: props.number,
    canvasWidth: props.number,
    fixedCommands: props.boolean,
    hideCommands: props.boolean,
    hideNavigationCommands: props.boolean,
    hideRotationCommands: props.boolean,
    hideZoomCommands: props.boolean,
    hidePrintCommand: props.boolean,
    hideFullScreenCommand: props.boolean,
    importPdfjsLib: props.boolean
  };

  public connectedCallback() {
    super.connectedCallback();
    this.emitEvent('ready');
    startWidgetMonitoring(SgwtWidgetName.PDF_READER);
  }

  private emitEvent(name: string, detail?: object) {
    emit(this, `${SgwtPdfReader.is}--${name}`, {
      detail: detail || {}
    });
  }

  public firstPage() {
    this.reference && this.reference.firstPage();
  }
  public previousPage() {
    this.reference && this.reference.previousPage();
  }
  public nextPage() {
    this.reference && this.reference.nextPage();
  }
  public lastPage() {
    this.reference && this.reference.lastPage();
  }

  public rotateLeft() {
    this.reference && this.reference.rotateLeft();
  }
  public rotateRight() {
    this.reference && this.reference.rotateRight();
  }

  public zoomIn() {
    this.reference && this.reference.zoomIn();
  }
  public zoomOut() {
    this.reference && this.reference.zoomOut();
  }
  public zoomReset() {
    this.reference && this.reference.zoomReset();
  }

  public fullScreen() {
    this.reference && this.reference.fullScreen();
  }

  public print() {
    this.reference && this.reference.print();
  }

  render() {
    const script = this.props.importPdfjsLib ?
      <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.9.622/pdf.combined.js"/> : undefined;
    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <WithGlobalCss styles={css}>
          { script }
          <PdfReader
            additionalI18n={this.props.i18n}
            ref={ r => { this.reference = r; } }
            pdfUrl={ this.props.pdfUrl }
            displayFullDocument={ this.props.displayFullDocument }
            canvasHeight = { this.props.canvasHeight }
            canvasMaxHeight= { this.props.canvasMaxHeight }
            canvasWidth = { this.props.canvasWidth }
            fixedCommands = { this.props.fixedCommands }
            hideCommands={ this.props.hideCommands }
            hideNavigationCommands={ this.props.hideNavigationCommands }
            hideRotationCommands={ this.props.hideRotationCommands }
            hideZoomCommands={ this.props.hideZoomCommands }
            hidePrintCommand={ this.props.hidePrintCommand }
            hideFullScreenCommand={ this.props.hideFullScreenCommand }
            onPdfLoaded={(pagesCount: number) => this.emitEvent('page-loaded', {pagesCount: pagesCount})}
            onPageLoaded={(page: number) => this.emitEvent('page-loaded', {page: page})}
            onPageRendered={(page: number) => this.emitEvent('page-rendered', {page: page})}
          />
        </WithGlobalCss>
      </WidgetConfigurationProvider>
    );
  }
}

initializeWidgetConfiguration(SgwtPdfReader, {
  disablejQuerySanityCheck: true,
  neverUseShadowDOM: true
});
customElements.define(SgwtPdfReader.is, SgwtPdfReader);
