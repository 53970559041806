
import { ISgwtPdfReaderI18N, ISgwtPdfReaderI18NLanguage } from './sgwt-pdf-reader.types';

const SGWT_PDF_READER_I18N_EN: ISgwtPdfReaderI18NLanguage = {
  loading: 'Your PDF is currently loading. Please wait...',
  first: 'First page',
  previous: 'Previous page',
  next: 'Next page',
  last: 'Last page',
  rotateLeft: 'Rotate counter clockwise',
  rotateRight: 'Rotate clockwise',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  zoomReset: 'Reset zoom (100%)',
  print: 'Print PDF',
  fullScreen: 'Display in full-screen',
  exitFullScreen: 'Exit the full-screen mode',
  changePage: 'Click to change the current page'
};

const SGWT_PDF_READER_I18N_FR: ISgwtPdfReaderI18NLanguage = {
  loading: 'Votre PDF est actuellement en train de charger. Merci de patienter...',
  first: 'Première page',
  previous: 'Page précédente',
  next: 'Page suivante',
  last: 'Dernière page',
  rotateLeft: 'Tourner dans le sens horaire contraire',
  rotateRight: 'Tourner dans le sens horaire',
  zoomIn: 'Zoomer',
  zoomOut: 'Dézoomer',
  zoomReset: 'Annuler le zoom (100%)',
  print: 'Imprimer le PDF',
  fullScreen: 'Afficher en plein écran',
  exitFullScreen: 'Quitter le mode plein écran',
  changePage: 'Cliquer pour changer la page courante'
};

const SGWT_PDF_READER_I18N_DE: ISgwtPdfReaderI18NLanguage = {
  loading: 'Ihr PDF wird gerade geladen. Bitte warten ...',
  first: 'Erste Seite',
  previous: 'Vorherige Seite',
  next: 'Nächste Seite',
  last: 'Letzte Seite',
  rotateLeft: 'Entgegen den Uhrzeigersinn drehen',
  rotateRight: 'Im Uhrzeigersinn drehen',
  zoomIn: 'Vergrößern',
  zoomOut: 'Verkleinern',
  zoomReset: 'Vergrößerung aufheben (100 %)',
  print: 'PDF ausdrucken',
  fullScreen: 'Vollbildmodus einschalten',
  exitFullScreen: 'Vollbildmodus ausschalten',
  changePage: 'Klicken, um eine andere Seite anzuzeigen'
};

const SGWT_PDF_READER_I18N_ES: ISgwtPdfReaderI18NLanguage = {
  loading: 'Su PDF actualmente se está cargando. Esperar...',
  first: 'Primera página',
  previous: 'Página precedente',
  next: 'Página siguiente',
  last: 'Última página',
  rotateLeft: 'Girar en el sentido contrario al horario',
  rotateRight: 'Girar en el sentido horario',
  zoomIn: 'Hacer zoom',
  zoomOut: 'Retirar el zoom',
  zoomReset: 'Cancelar el zoom (100%)',
  print: 'Imprimir el PDF',
  fullScreen: 'Visualizar en pantalla completa',
  exitFullScreen: 'Salir del modo pantalla completa',
  changePage: 'Hacer clic para cambiar la página actual'
};

const SGWT_PDF_READER_I18N_ZH: ISgwtPdfReaderI18NLanguage = {
  loading: '您的PDF正在下载。 请耐心等候……',
  first: '首页',
  previous: '上一页',
  next: '下一页',
  last: '末页',
  rotateLeft: '逆时间方向转',
  rotateRight: '顺时间方向转',
  zoomIn: '放大',
  zoomOut: '缩小',
  zoomReset: '取消调焦距 （100%）',
  print: '打印PDF',
  fullScreen: '全屏',
  exitFullScreen: '退出全屏',
  changePage: '单击以更改当前页面'
};

export const SGWT_PDF_READER_I18N: ISgwtPdfReaderI18N = {
  en: SGWT_PDF_READER_I18N_EN,
  fr: SGWT_PDF_READER_I18N_FR,
  de: SGWT_PDF_READER_I18N_DE,
  es: SGWT_PDF_READER_I18N_ES,
  zh: SGWT_PDF_READER_I18N_ZH
};
