import { h, FunctionalComponent } from 'preact';
import { ISgwtPdfReaderI18NLanguage } from '../sgwt-pdf-reader.types';
import { SvgIcon } from '../../common/components';

export interface IPrintControlProps {
  labels: ISgwtPdfReaderI18NLanguage;
  onPrint: () => void;
  printing: boolean;
}

export const PrintControl: FunctionalComponent<IPrintControlProps> = (props: IPrintControlProps) => {
  return (
    <div class="btn-group mr-2 sgwt-pdf-reader-commands-print">
      <div class="btn btn-outline-primary" onClick={props.onPrint} title={props.labels.print}>
        <i class="sgwt-widgets-icon icon-sm">
          {
            props.printing ?
              <SvgIcon type="autorenew" class="animation-spin"/> :
              <SvgIcon type="print"/>
          }
        </i>
      </div>
    </div>
  );
};
