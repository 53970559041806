import { h, FunctionalComponent } from 'preact';
import { ISgwtPdfReaderI18NLanguage } from '../sgwt-pdf-reader.types';
import { SvgIcon } from '../../common/components';

export interface IFullScreenControlProps {
  labels: ISgwtPdfReaderI18NLanguage;
  isFullScreen: boolean;
  onExitFullScreen: () => void;
  onFullScreen: () => void;
}

export const FullScreenControl: FunctionalComponent<IFullScreenControlProps> = (
  {
    labels,
    isFullScreen,
    onExitFullScreen,
    onFullScreen
  }: IFullScreenControlProps) => {

  const isFullScreenCapable = document.fullscreenEnabled
    || (document as any).webkitFullscreenEnabled
    || (document as any).mozFullScreenEnabled
    || (document as any).msFullscreenEnabled;

  const _handleClick = () => {
    if (!isFullScreenCapable) {
      return;
    }
    isFullScreen ? onExitFullScreen() : onFullScreen();
  };

  return (
    <div class="btn-group mr-2 sgwt-pdf-reader-commands-fullscreen">
      <div class={`btn btn-outline-primary ${isFullScreenCapable ? '' : 'disabled'}`}
        onClick={_handleClick}
        title={isFullScreen ? labels.exitFullScreen : labels.fullScreen}>
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type={isFullScreen ? 'fullscreen_exit' : 'fullscreen'}/>
        </i>
      </div>
    </div>
  );
};
