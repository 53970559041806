import { h, FunctionalComponent } from 'preact';
import { ISgwtPdfReaderI18NLanguage } from '../sgwt-pdf-reader.types';
import { SvgIcon } from '../../common/components';

export interface IRotationControlsProps {
  labels: ISgwtPdfReaderI18NLanguage;
  onRotateLeft: () => void;
  onRotateRight: () => void;
}

export const RotationControls: FunctionalComponent<IRotationControlsProps> = (props: IRotationControlsProps) => {
  return (
    <div class="btn-group mr-2 sgwt-pdf-reader-commands-rotation">
      <div class="btn btn-outline-primary" onClick={props.onRotateLeft} title={props.labels.rotateLeft}>
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type="rotate_left"/>
        </i>
      </div>
      <div class="btn btn-outline-primary" onClick={props.onRotateRight} title={props.labels.rotateRight}>
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type="rotate_right"/>
        </i>
      </div>
    </div>
  );
};
