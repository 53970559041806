import { h, FunctionalComponent } from 'preact';
import { ISgwtPdfReaderI18NLanguage } from '../sgwt-pdf-reader.types';
import { SvgIcon } from '../../common/components';

interface IZoomControlsProps {
  labels: ISgwtPdfReaderI18NLanguage;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
}

export const ZoomControls: FunctionalComponent<IZoomControlsProps> = (props: IZoomControlsProps) => {
  return (
    <div class="btn-group mr-2 sgwt-pdf-reader-commands-zoom">
      <div class="btn btn-outline-primary" onClick={props.onZoomIn} title={props.labels.zoomIn}>
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type="zoom_in"/>
        </i>
      </div>
      <div class="btn btn-outline-primary" onClick={props.onZoomReset} title={props.labels.zoomReset}>
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type="zoom_reset"/>
        </i>
      </div>
      <div class="btn btn-outline-primary" onClick={props.onZoomOut} title={props.labels.zoomOut}>
        <i class="sgwt-widgets-icon icon-sm">
          <SvgIcon type="zoom_out"/>
        </i>
      </div>
    </div>
  );
};
