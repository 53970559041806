import { h, Component } from 'preact';
import { ISgwtPdfReaderI18NLanguage } from '../sgwt-pdf-reader.types';
import { SvgIcon } from '../../common/components';

export interface INavigationControlsProps {
  labels: ISgwtPdfReaderI18NLanguage;
  currentPage: number;
  pagesCount: number;
  onPageChanged: (newPage: number) => void;
}

export interface INavigationControlsState {
  edition: boolean;
  error: boolean;
  newPage: number;
}

export default class NavigationControls extends Component<INavigationControlsProps, INavigationControlsState> {
  public state: INavigationControlsState = {
    edition: false,
    error: false,
    newPage: this.props.currentPage
  };

  componentDidUpdate(previousProps: INavigationControlsProps) {
    if (this.props.currentPage !== previousProps.currentPage) {
      this.setState({
        newPage: this.props.currentPage
      });
    }
  }

  private isFirstPage() {
    return this.props.currentPage === 1;
  }

  private isLastPage() {
    return this.props.currentPage === this.props.pagesCount;
  }

  firstPage() {
    if (!this.isFirstPage()) {
      this.props.onPageChanged(1);
    }
  }

  previousPage() {
    if (!this.isFirstPage()) {
      this.props.onPageChanged(this.props.currentPage - 1);
    }
  }

  nextPage() {
    if (!this.isLastPage()) {
      this.props.onPageChanged(this.props.currentPage + 1);
    }
  }

  lastPage() {
    if (!this.isLastPage()) {
      this.props.onPageChanged(this.props.pagesCount);
    }
  }

  private startEdition() {
    this.setState({
      edition: true
    });
  }

  stopEdition() {
    this.setState({
      edition: false
    });
  }

  selectPage() {
    const goTo: number = +this.state.newPage;
    if (goTo >= 1 && goTo <= this.props.pagesCount && goTo !== this.props.currentPage) {
      this.props.onPageChanged(goTo);
      this.setState({
        edition: false,
        error: false
      });
    } else {
      this.setState({
        error: true
      });
    }
  }

  valueChanged(event: Event) {
    this.setState({
      newPage: (event.target as any).value
    });
  }

  render(props: INavigationControlsProps, state: INavigationControlsState) {
    const first = this.isFirstPage();
    const last = this.isLastPage();
    const newPage: string = `${state.newPage}`;

    return (
      <div class="btn-group mr-2 sgwt-pdf-reader-commands-navigation">
        <div class={`btn btn-outline-primary ${first ? 'disabled' : ''}`}
          onClick={ () => this.firstPage() }
          title={props.labels.first}>
          <i class="sgwt-widgets-icon icon-sm">
            <SvgIcon type="first_page"/>
          </i>
        </div>
        <div class={`btn btn-outline-primary ${first ? 'disabled' : ''}`}
          onClick={ () => this.previousPage() }
          title={props.labels.previous}>
          <i class="sgwt-widgets-icon icon-sm">
            <SvgIcon type="navigate_before"/>
          </i>
        </div>
        <div>
          {
            state.edition ?
              <input class={`form-control ${state.error ? 'is-invalid' : ''}`}
                style={{ width: '64px'}}
                autofocus
                value={ newPage }
                onChange={ event => this.valueChanged(event) }
                onBlur={ () => this.stopEdition() }
                onKeyUp={ event => { if (event.keyCode === 13) { this.selectPage(); } } }/>
              :
              <div class="btn btn-outline-primary" title={ props.labels.changePage } onClick={ () => this.startEdition() }>
                { props.currentPage } / { props.pagesCount }
              </div>
          }
        </div>
        <div class={`btn btn-outline-primary ${last ? 'disabled' : ''}`}
          onClick={ () => this.nextPage() }
          title={props.labels.next}>
          <i class="sgwt-widgets-icon icon-sm">
            <SvgIcon type="navigate_next"/>
          </i>
        </div>
        <div class={`btn btn-outline-primary ${last ? 'disabled' : ''}`}
          onClick={ () => this.lastPage() }
          title={props.labels.last}>
          <i class="sgwt-widgets-icon icon-sm">
            <SvgIcon type="last_page"/>
          </i>
        </div>
      </div>
    );
  }
}
